import Image from 'components/Image';

const Restricted = () => {
  return (
    <div className="h-[100dvh] bg-siteBG2 w-full">
      <div className="flex items-center justify-center h-full">
        <div className="">
          <p className="hidden">Restricted</p>
          <Image
            imgClassName="max-w-full max-h-[32dvh] mx-auto"
            src="/images/404.svg"
          />
          <div className="max-w-[350px] mx-auto text-center mt-7 flex flex-col">
            <p className="text-sm leading-5 font-medium text-grayText ">
              Our platform does not support I frame.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restricted;
