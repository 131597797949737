export const {
  REACT_APP_URL,
  REACT_APP_FRONT_URL,
  REACT_APP_API_URL,
  REACT_APP_API_BASE_URL,
  SERVER_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FRONT_CMS_URL,
  REACT_APP_GOOGLE_TRANSLATE,
  REACT_APP_GOOGLE_MAPS_ID,
  SENTRY_DNS,
  REACT_APP_GOOGLE_MAP_ENABLED,
  REACT_APP_DATE_FORMAT,
  REACT_APP_DATE_FORMAT_EUROPEAN,
  REACT_APP_CERVED_API_KEY,
  REACT_APP_CAPTCHA_SITE_KEY,
  REACT_APP_RESTRICT_DUMMY_EMAIL_PROVIDERS,
  REACT_APP_ENCRYPTION_KEY,
} = process.env;
