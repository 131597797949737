import './style/Loader.css';

interface LoadersProps {
  type?: 'Spin' | 'SiteLoader';
  className?: string;
}

const Loaders = ({ type, className }: LoadersProps) => {
  return (
    <>
      {type === 'SiteLoader' ? (
        <div
          className={`fixed top-0 left-0 w-full h-full z-5 bg-dark/70 flex items-center justify-center ${
            className ?? ''
          }`}
        >
          <div className="w-48 h-48">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1817 1187"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-full h-full"
            >
              <path
                d="M1224.66 1185.34C1550.88 1185.34 1815.33 920.325 1815.33 593.42C1815.33 266.515 1550.88 1.5 1224.66 1.5C898.441 1.5 633.993 266.515 633.993 593.42C633.993 920.325 898.441 1185.34 1224.66 1185.34Z"
                fill="#0B4565"
                stroke="#0B4565"
                strokeWidth="3"
                className="svg-elem-1"
              />
              <path
                d="M845.188 274.807L845.908 275.422L846.773 275.037C934.199 236.059 1000.88 215.578 1065.23 210.778C1129.57 205.978 1191.72 216.844 1270.09 240.757L1271.36 238.074L1270.52 239.322C1271.36 238.074 1271.35 238.073 1271.35 238.072L1271.35 238.069L1271.33 238.057L1271.26 238.013C1271.2 237.974 1271.12 237.916 1271 237.84C1270.77 237.689 1270.43 237.467 1269.97 237.178C1269.07 236.6 1267.73 235.757 1265.98 234.691C1262.49 232.56 1257.38 229.54 1250.87 225.972C1237.84 218.838 1219.23 209.512 1196.86 200.741C1152.14 183.207 1092.26 167.833 1031.92 176.726C971.776 185.589 926.648 202.432 896.536 217.076C881.48 224.398 870.18 231.17 862.633 236.119C858.86 238.593 856.025 240.612 854.129 242.016C853.391 242.563 852.796 243.016 852.342 243.367C852.296 243.32 852.248 243.271 852.199 243.22C851.483 242.488 850.423 241.412 849.047 240.032C846.295 237.272 842.278 233.296 837.213 228.431C827.084 218.7 812.758 205.405 795.97 191.144C762.432 162.653 718.93 130.189 679.384 114.69C628.172 94.619 569.947 93.3712 524.618 97.104C501.937 98.9717 482.447 102.09 468.625 104.741C461.714 106.067 456.217 107.277 452.446 108.156C450.56 108.595 449.105 108.952 448.12 109.2C447.627 109.323 447.252 109.42 446.999 109.486C446.872 109.518 446.777 109.544 446.712 109.561L446.638 109.58L446.619 109.585L446.614 109.587C446.613 109.587 446.612 109.587 447 111.036L446.612 109.587L447 112.536C522.456 112.536 586.139 122.491 648.856 147.719C711.589 172.953 773.44 213.503 845.188 274.807Z"
                fill="white"
                stroke="white"
                strokeWidth="3"
                className="svg-elem-2"
              />
              <path
                d="M0 458.55C42.8071 458.083 85.6169 457.81 128.421 458.21C147.571 458.389 166.342 462.183 183.446 471.012C195.384 477.176 205.183 486.257 212.975 497.607C221.595 510.163 226.664 523.943 228.527 538.757C231.483 562.255 229.697 585.157 218.751 606.914C210.031 624.247 197.153 637.223 179.697 644.874C169.57 649.313 158.446 652.131 147.484 653.882C117.26 658.708 86.7224 655.169 56.33 655.952C51.4708 656.077 49.8131 656.982 49.8647 662.235C50.1459 690.881 49.8754 719.534 50.1154 748.181C50.1569 753.144 48.6134 754.287 43.9088 754.19C29.5904 753.895 15.2619 754.083 0.468663 754.083C0 655.728 0 557.374 0 458.55ZM155.556 602.211C156.233 601.52 156.79 600.591 157.607 600.177C166.654 595.585 171.655 587.673 174.666 578.513C178.34 567.335 178.686 555.712 176.772 544.192C175.182 534.617 171.381 525.697 164.171 518.959C154.056 509.507 141.409 505.462 127.9 505.208C103.766 504.755 79.6155 505.19 55.4744 504.974C51.0367 504.935 49.8762 506.211 49.9016 510.588C50.0833 541.89 50.1116 573.194 49.8806 604.495C49.8447 609.366 51.5829 610.201 55.8955 610.165C77.706 609.986 99.5195 609.996 121.331 610.118C133.143 610.185 144.247 607.429 155.556 602.211Z"
                fill="white"
                className="svg-elem-3"
              />
              <path
                d="M864.002 710.194C896.584 725.63 935.69 717.998 955.86 691.6C960.429 685.621 964.879 683.835 971.857 684.092C984.8 684.57 997.772 684.229 1012.25 684.229C1008.33 692.024 1005.43 699.278 1001.31 705.764C980.359 738.768 949.839 756.035 911.164 758.953C885.837 760.864 861.113 758.002 838.559 745.352C802.303 725.017 782.951 693.602 780.316 652.304C777.869 613.943 789.277 580.668 818.936 554.856C836.021 539.986 856.051 531.611 878.368 528.673C904.646 525.213 930.479 527.031 954.411 539.171C994.748 559.632 1015.64 593.086 1017.83 638.197C1018.15 644.634 1017.88 651.101 1017.88 657.949C953.634 657.949 890.525 657.949 826.943 657.949C831.126 681.452 842.652 699.071 864.002 710.194ZM890.806 569.169C858.436 572.911 831.595 596.209 828.509 623.441C875.325 623.441 922.116 623.441 969.407 623.441C965.588 606.459 957.062 593.04 943.627 582.785C928.26 571.055 910.543 567.974 890.806 569.169Z"
                fill="white"
                className="svg-elem-4"
              />
              <path
                d="M1444.54 744.616C1398.36 766.31 1353.15 766.337 1310.43 737.705C1281.62 718.401 1266.95 689.643 1264.22 655.181C1261.9 625.787 1267.13 598.023 1285.11 573.797C1305.65 546.119 1333.48 531.332 1367.62 528.03C1391.42 525.729 1414.78 527.759 1436.4 538.347C1475.37 557.429 1497.38 588.766 1500.97 632.332C1501.65 640.577 1501.06 648.927 1501.06 657.732C1437.6 657.732 1374.54 657.732 1310.87 657.732C1312.07 668.525 1315.62 677.873 1321.22 686.346C1337.65 711.213 1361.8 719.633 1390.31 717.514C1412.89 715.835 1430.88 705.622 1443.6 686.555C1444.35 685.417 1446.17 684.344 1447.51 684.331C1463.16 684.177 1478.81 684.232 1495.2 684.232C1485.65 710.875 1468.82 730.596 1444.54 744.616ZM1330.55 589.12C1321.06 598.762 1314.59 609.978 1311.91 623.445C1359.27 623.445 1405.98 623.445 1453.27 623.445C1446.78 598.778 1432.88 580.799 1408.78 573.185C1380.5 564.254 1353.81 567.638 1330.55 589.12Z"
                fill="white"
                className="svg-elem-5"
              />
              <path
                d="M1583.93 665.082C1583.93 694.562 1583.93 723.542 1583.93 752.954C1568.83 752.954 1553.95 752.954 1538.89 752.954C1538.78 752.191 1538.56 751.392 1538.56 750.593C1538.69 708.765 1538.14 666.92 1539.26 625.119C1539.98 598.042 1548.98 573.514 1569.37 554.362C1585.63 539.079 1605.36 531.248 1627.19 528.535C1651.34 525.532 1675.17 526.978 1697.82 536.679C1728.76 549.923 1746.6 573.934 1752.76 606.576C1754.81 617.472 1755.68 628.728 1755.79 639.833C1756.16 675.992 1755.93 712.158 1755.93 748.322C1755.93 749.792 1755.93 751.262 1755.93 753.057C1740.83 753.057 1726.09 753.057 1710.53 753.057C1710.53 751.188 1710.53 749.284 1710.53 747.38C1710.53 711.883 1710.78 676.383 1710.37 640.891C1710.26 630.99 1709.33 620.807 1706.79 611.283C1700.21 586.649 1681.03 571.343 1655.58 569.257C1639.48 567.937 1623.93 569.766 1610.1 579.07C1591.64 591.484 1585.31 610.136 1584.17 631.091C1583.56 642.226 1583.98 653.417 1583.93 665.082Z"
                fill="white"
                className="svg-elem-6"
              />
              <path
                d="M1053.64 607.511C1043.91 582.751 1034.33 558.346 1024.48 533.243C1036.83 533.243 1048.42 533.202 1060.02 533.266C1064.64 533.291 1070.42 531.923 1073.54 534.16C1076.71 536.419 1077.35 542.316 1078.91 546.686C1097.89 599.933 1116.85 653.186 1135.75 706.463C1136.82 709.48 1137.68 711.531 1141.73 711.449C1145.62 711.371 1146.26 709.297 1147.24 706.518C1167.23 650.271 1187.32 594.059 1207.22 537.786C1208.5 534.168 1210.16 533.073 1213.95 533.142C1228.09 533.397 1242.24 533.245 1257.07 533.245C1253.86 541.58 1250.88 549.461 1247.79 557.303C1222.67 621.212 1197.5 685.101 1172.49 749.053C1171.18 752.407 1169.63 753.516 1166.07 753.474C1149.41 753.279 1132.75 753.234 1116.09 753.499C1112.07 753.563 1110.35 752.288 1108.91 748.595C1090.64 701.644 1072.19 654.764 1053.64 607.511Z"
                fill="white"
                className="svg-elem-7"
              />
              <path
                d="M743.079 496.081C743.079 582.029 743.079 667.476 743.079 753.153C727.824 753.153 713.084 753.153 698.098 753.153C698.098 646.358 698.098 539.792 698.098 433C713.084 433 727.824 433 743.079 433C743.079 454.013 743.079 474.797 743.079 496.081Z"
                fill="white"
                className="svg-elem-8"
              />
              <path
                d="M402.775 725.294C388.444 709.986 378.606 692.502 374.699 672.285C371.956 658.099 369.522 643.683 372.761 629.055C374.078 623.103 373.941 616.814 375.418 610.917C379.114 596.167 385.606 582.633 395.03 570.636C408.26 553.792 424.963 541.505 445.062 534.196C457.205 529.78 469.784 526.655 482.875 527.129C494.945 527.566 507.192 527.284 519.021 529.305C540.164 532.915 558.679 542.51 574.654 557.139C591.151 572.247 602.25 590.612 607.287 612.122C612.799 635.658 612.411 659.324 605.041 682.714C597.024 708.157 581.056 727.313 559.26 741.924C544.143 752.059 527.163 757.617 509.158 758.924C495.82 759.893 482.191 760.384 468.987 758.73C454.672 756.937 440.431 752.891 428.166 744.638C419.45 738.773 411.378 731.95 402.775 725.294ZM549.485 689.012C557.436 678.054 561.883 665.63 562.852 652.319C564.758 626.155 558.521 602.761 537.506 585.062C529.093 577.975 519.272 573.404 508.737 571.362C500.453 569.756 491.724 570.039 483.203 570.169C470.619 570.36 459.058 574.747 448.981 581.888C428.803 596.189 419.47 616.495 419.056 640.932C418.759 658.435 422.132 674.838 432.989 689.429C441.361 700.68 451.897 708.547 464.866 712.952C476.885 717.033 489.316 717.696 502.104 716.257C521.78 714.044 537.128 705.044 549.485 689.012Z"
                fill="white"
                className="svg-elem-9"
              />
              <path
                d="M251.892 589.981C255.22 576 261.381 563.911 270.95 553.738C281.543 542.476 294.72 535.48 309.464 531.173C319.9 528.125 330.496 526.788 341.368 527.034C351.191 527.257 361.024 527.125 370.852 527.056C373.387 527.038 374.941 527.524 374.914 530.579C374.812 542.24 374.812 553.901 374.915 565.561C374.942 568.596 373.432 569.132 370.874 569.108C362.546 569.029 354.203 568.788 345.891 569.16C336.298 569.589 326.842 570.771 318.059 575.391C307.888 580.742 301.717 589.154 298.234 599.755C294.477 611.193 294.083 623.112 294.046 634.991C293.928 672.638 293.887 710.286 294.134 747.933C294.169 753.19 292.556 754.335 287.66 754.192C276.009 753.853 264.339 753.941 252.682 754.163C248.862 754.236 247.926 752.911 247.935 749.271C248.04 703.294 247.844 657.316 248.175 611.342C248.225 604.35 250.551 597.374 251.892 589.981Z"
                fill="white"
                className="svg-elem-10"
              />
            </svg>
          </div>
        </div>
      ) : (
        <span
          className={`animate-spin h-5 w-5 inline-block border-4 border-solid border-gray-300/50 border-l-gray-300 rounded-full ${
            className ?? ''
          }`}
        />
      )}
    </>
  );
};

export default Loaders;
